import { render, staticRenderFns } from "./KanbanView.vue?vue&type=template&id=77de7d13&scoped=true&"
import script from "./KanbanView.vue?vue&type=script&lang=js&"
export * from "./KanbanView.vue?vue&type=script&lang=js&"
import style0 from "./KanbanView.vue?vue&type=style&index=0&lang=css&"
import style1 from "./KanbanView.vue?vue&type=style&index=1&lang=css&"
import style2 from "./KanbanView.vue?vue&type=style&index=2&id=77de7d13&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77de7d13",
  null
  
)

export default component.exports